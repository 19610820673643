import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, forkJoin } from "rxjs";
import { CommonService } from '../../../../shared/services/common.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    public overallDashboardData : Subject<any> = new Subject<any>();
    public overallDashboard7daysMovementData : Subject<any> = new Subject<any>();
    public othersDashboardData : Subject<any> = new Subject<any>();
    public liveBookingDashboardData : Subject<any> = new Subject<any>();
    public teamsDashboardData : Subject<any> = new Subject<any>();
    public inventoryCollectionData : Subject<any> = new Subject<any>();
    public bookingRetailData: Subject<any> = new Subject<any>();

    public onloadfilter: Subject<any> = new Subject<any>();
    public commonFilter: Subject<any> = new Subject<any>();

    public modelItems : Subject<any> = new Subject<any>();
    public teamItems : Subject<any> = new Subject<any>();

    currentPage : string = '';

    public masterTeamListData: any = [];

    constructor(private service: CommonService) { }

    getModelList(branches : any) {

      this.service.postService("/product/listModel", branches).subscribe((res: any) => {

        if (res.status == 200) {

          let modelList = [...new Set((res.results).map((obj : any)=>obj?.model))];

          this.modelItems.next(modelList);

        }

        else{

          this.modelItems.next([]);

        }

      }, err => {

        this.service.showToastr('ModelList getting failure!', 'error');

      });

    }

    overallData(
      
      {branchId = [], team = [], empId = [], finExe = []}: 

      {branchId?: string[], team?: string[], empId?: string[], finExe?: string[]} 

    ) {

      let postData = _.pickBy({ branchId, team, empId, finExe },(value,key)=>{
        return value.length>0
      });

      this.service.postService("/dashboard/overallBooking", postData,{showLoader:true}).subscribe(res => {

        this.overallDashboardData.next( res.status == 200 ? res.results : []);

      }, error => {

        this.service.showToastr("Data fetching error", 'error');

        this.overallDashboardData.next([]);

      });

      this.service.postService("/dashboard/last7daysMovement", postData,{showLoader:true}).subscribe(res => {

          this.overallDashboard7daysMovementData.next( res.status == 200 ? res.results : []);

      }, error => {

        this.service.showToastr("Data fetching error", 'error');

        this.overallDashboard7daysMovementData.next([]);

      });


    }

    getOthersDashboardData(

      { branchId = [], team = [], empId = [], finExe = [] }:

      { branchId?: string[], team?: string[], empId?: string[], finExe?: string[] }

    ) {

      let postData = _.pickBy({ branchId, team, empId, finExe },(value,key)=>{
        return value.length>0
      });

      this.service.postService("/dashboard/chargeOfOthers", postData, {showLoader:true}).subscribe(res => {

          this.othersDashboardData.next( res.status == 200 ? res.results : []);

      }, error => {

        this.service.showToastr("Data fetching error", error);

        this.othersDashboardData.next([]);

      }); 


    }

    liveData(
      
      { branchId = [], team = [], empId = [], finExe = [] }: 

      { branchId?: string[], team?: string[], empId?: string[], finExe?: string[] }
      
    ) {

      let postData = _.pickBy({ branchId, team, empId, finExe },(value,key)=>{
        return value.length>0
      });

      this.service.postService("/dashboard/liveBooking", postData,{showLoader:true}).subscribe(res => {

          this.liveBookingDashboardData.next( res.status == 200 ? res.results : []);

      }, error => {

        this.service.showToastr("Data fetching error", error);

        this.liveBookingDashboardData.next([]);

      }); 

    }
    
    // ---------------------- Api service for Team performance Dashboard Menu -------------

  teamsData({branchId = [], empId = [], finExe = [] } : {branchId?: string[], empId?: string[], finExe : string[]}){
    
    this.service.postService("/dashboard/teamPerformance", { branchId,  empId, finExe },{showLoader:true}).subscribe(res => {

      this.teamsDashboardData.next( res.status == 200 ? res.results : []);

    }, error => {

      this.service.showToastr("Data fetching error", error);

      this.teamsDashboardData.next([]);

    }); 

  }

  getInventoryCollectionData() {

    forkJoin([

      

      this.service.postService("/dashboard/invAgeing", {}, {showLoader:true} ),

      this.service.postService("/dashboard/bookingAndRetail", { month: (new Date().getMonth() + 1).toString().padStart(2,'0'), 'year': new Date().getFullYear().toString() }, {showLoader:true} ),

    ]).subscribe((res: any) => {

        this.inventoryCollectionData.next( res[0].status == 200 ? res[0].results : []);

        this.bookingRetailData.next( res[1].status == 200 ? res[1].results : []);

    }, error => {

      this.service.showToastr("Data fetching error", error);

      this.inventoryCollectionData.next([]);

      this.bookingRetailData.next([]);

    }); 

  }
    
}
