import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionGuard implements CanActivate, CanActivateChild {

  permissionDetails: any = {};
  token: string;

  constructor( private service: CommonService, private router: Router ) {

    this.permissionDetails = JSON.parse(this.service.session('get','permissionDetails'));

    this.token = this.service.session('get','Authorization');

  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.token)  return true;

    else {

      this.service.logout();

      return false;

    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var mainMenuDet = this.permissionDetails[state.url.split('/')[2]];

    var subMenuDet = mainMenuDet.subMenu[state.url.split('/')[3]];

    if(subMenuDet) return true;

    this.router.navigate(['/root/403']);

    return false;

  }

}
